import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { Spin, Image, Button,message,Popover,Typography } from 'antd';
import { DeleteOutlined, ReadOutlined,FilePdfOutlined,FilterOutlined } from '@ant-design/icons';
import { setSelectedItem } from '../../redux/actions/dashboardActions';
import { useIndexedDB } from 'react-indexed-db';
import Space from '../../component/dashboard/space';
import Emptycard from '../../component/Empty';
import './styles.css';
import Skeletonbook from '../../component/skeleton/skeletonbook';
import Bookcover from '../../api/images/book.png'

const { Title } = Typography;

const Downloaded = (props) => {
    const { getAll, deleteRecord } = useIndexedDB('book');
    const { clear } = useIndexedDB('epub');
    const history = useHistory();
    const dispatch = useDispatch();
    const [downloaded, setdownloaded] = useState([])
    const [loading, setloading] = useState(true)
    const [pdf, setpdf] = useState([])
    const [epub, setepub] = useState([])

    const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));


    useEffect(() => {
        window.scrollTo(0, 0)
        clear().then(() => {
            console.log(" Clear old data!");
          })
        getAll().then(files => {
            const userFiles = files.filter(e => e.userId === lpp_userCredentials.id);
            setdownloaded(userFiles)
            let epub = files.filter(e => e.type === "epub" && e.userId === lpp_userCredentials.id);
            setepub(epub)
            let pdf = files.filter(e => e.type === "pdf" && e.userId === lpp_userCredentials.id);
            setpdf(pdf)
            setloading(false)
        });
    }, [])


    const deletePost = async (id) => {
        deleteRecord(id).then(event => {
            message.success('Book Deleted from your downloaded list');

        });
        getAll().then(files => {
            const userFiles = files.filter(e => e.userId === lpp_userCredentials.id);
            let pdf = files.filter(e => e.type === "pdf" && e.userId === lpp_userCredentials.id);
            let epub = files.filter(e => e.type === "epub" && e.userId === lpp_userCredentials.id);
            setdownloaded(userFiles)
            setpdf(pdf)
            setepub(epub)
            setloading(false)
        });
    }
    const deletemultipulPost = async (epubid,pdfid) => {
        deleteRecord(epubid).then(event => {

        });
        deleteRecord(pdfid).then(event => {
            message.success('Book Deleted from your downloaded list');

        });
        getAll().then(files => {
            const userFiles = files.filter(e => e.userId === lpp_userCredentials.id);
            let pdf = files.filter(e => e.type === "pdf" && e.userId === lpp_userCredentials.id);
            let epub = files.filter(e => e.type === "epub" && e.userId === lpp_userCredentials.id);
            setdownloaded(userFiles)
            setpdf(pdf)
            setepub(epub)
            setloading(false)
        });
    }
    const add3Dots = (string, limit) => {
        var dots = "...";
        if (string.length > limit) {
            // you can also use substr instead of substring
            string = string.substring(0, limit) + dots;
        }

        return string;
    }
   

    const goTopdf = (item) => {
        history.push({pathname:'/read/book/pdf/'+"0000",state:{contentid:"0000",fuzzyid:"0000" ,isbn:"0000",name:item.name,type:"book",isonline:'no'}})

    }

    const goToepub = (item) => {
        history.push({pathname:'/read/book/epub/'+"offline",state:{contentid:"0000",isbn:"0000",name:item.name,type:"book"}})

        // history.push('/reader/'+"0000"+"/"+"0000"+"/"+item.name+"/"+"000"+"/"+"no"+"/"+"book")

    }
    const readEpub = (item) => {
        if (item.type == "epub") {
            return (
                <div style={{width:'100%'}}>
                <div className='sub-read'>
                       <div className='sub-read-name'>EBOOK (EPUB)</div>
                       <div className="icons">
                        <ReadOutlined onClick={()=>goToepub(item)} className="readr-icon" style={{padding:'6px'}}/>    </div>
                    </div>
                    </div>
            )
        } else {
            return (
                <div style={{width:'100%'}}>
                <div className='sub-read'>
                       <div className='sub-read-name'>EBOOK (PDF)</div>
                       <div className="icons">
                        <FilePdfOutlined onClick={()=>goTopdf(item)} className="readr-icon" style={{padding:'6px'}}/>    </div>
                    </div>
                    </div>
             
            )
        }
    }
    const content =(item,ishavepdf)=> (
        <div>
          <p style={{fontFamily: 'var(--font)',letterSpacing:'1px'}} onClick={()=>{goToepub(item)}}> <ReadOutlined style={{fontSize:'15px',marginTop:'10px',color:'blue',marginRight:'10px'}} />Epub</p>
          <p style={{fontFamily: 'var(--font)',letterSpacing:'1px'}} onClick={()=>{goTopdf(ishavepdf)}}> <FilePdfOutlined style={{fontSize:'15px',marginTop:'10px',color:'red',marginRight:'10px'}} />Pdf</p>
        </div>
      );

      if(loading){
          return(<Skeletonbook/>)
      }

    if (downloaded.length == 0) {
        return (
            <div style={{ display:"flex", height: '90vh', width: '100%', alignItems: "center", justifyContent: 'center' }}>
                    <Emptycard  descriptin="You don't have any downloaded Book " link={()=>{props.setsub()}}/>
                
            </div>
        )
    }
    return (

        <div className="cards-container">
            {epub.map((item) => {
                let ishavepdf = pdf.find(e => e.name.replace("-pdf","") == item.name );
                 if(ishavepdf ){
                   return (
            <div  className="ion-card" >
                <div className="bookcover_continer" >
                    <Image  className="bookcover"
                        preview={false}
                        fallback={Bookcover}
                        src={URL.createObjectURL(item.img)} />
                </div>
                <div className="bookcontent">
                    <div >
                    <div className="booktitle" >{item.name}</div>
                </div>
                <div>
                    <div className='sub-read'>
                       <div className='sub-read-name'>EBOOK (EPUB)</div>
                       <div className="icons">
                        <ReadOutlined onClick={()=>goToepub(item)} className="readr-icon" style={{padding:'6px'}}/>    </div>
                    </div>
                    <div className='sub-read'>
                    <div className='sub-read-name'> EBOOK (PDF)</div>
                       <div className="icons">
                        <FilePdfOutlined onClick={()=>goTopdf(ishavepdf)} className="readr-icon" style={{padding:'6px'}}/>    </div>
                    </div>
                    <div className='card-sharerevo'>
                    <div onClick={()=>deletemultipulPost(item.id,ishavepdf.id)} className='removesect'>{<DeleteOutlined className='favrem-icon' />}Delete</div>
                     </div>
                </div>
                </div>
            </div>
                   )
               }else{
                return (
                    <div  className="ion-card" >
                    <div className="bookcover_continer" >
                        <Image className="bookcover"
                            preview={false}
                            fallback={Bookcover}
                            src={URL.createObjectURL(item.img)} />
                    </div>
                    <div className="bookcontent">
                        <div>
                        <div className="booktitle" >{item.name}</div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '10px' ,marginBottom:'10px'}}>
                           {readEpub(item)}
                        </div>
                        <div className='card-sharerevo'>

                        <div onClick={()=>deletePost(item.id)} className='removesect'>{<DeleteOutlined className='favrem-icon' />}Delete</div>
</div>
                    </div>
                </div>
                   )}
            })}
             {pdf.map((item) => {
                let ishavepub = epub.find(e => e.name == item.name.replace("-pdf","") );
                if(ishavepub){
                    return null
                }
                return (
                    <div  className="ion-card" >
                    <div className="bookcover_continer" >
                        <Image  className="bookcover"
                            preview={false}
                            fallback={Bookcover}
                            src={URL.createObjectURL(item.img)} />
                    </div>
                    <div className="bookcontent">
                        <div>
                        <div className="booktitle" >{item.name.replace("-pdf","")}</div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '10px',marginBottom:'10px' }}>
                           {readEpub(item)}
                        </div>
                        <div className='card-sharerevo'>

                        <div onClick={()=>deletePost(item.id)} className='removesect'>{<DeleteOutlined className='favrem-icon' />}Delete</div>
</div>
                    </div>
                </div>
                   )
            })}
            <Space hig="10px" color="#ffffff" />
        </div>


    )
}

export default Downloaded;