import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { EpubView } from "..";
import defaultStyles from "./style";
import { connect } from "react-redux"
import 'antd/dist/antd.css';
import { FileSearchOutlined, HighlightOutlined, BookOutlined, DeleteOutlined, LeftOutlined, RightOutlined, AlignCenterOutlined, CloseOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Drawer, Card, Input, message, Button } from 'antd';
import { settocopen} from '../../redux/actions/dashboardActions';
import moment from "moment";
import Swaip from '../../component/Swaip'
import { BiArrowBack } from "react-icons/bi";
const { Search } = Input;
class TocItem extends PureComponent {
  setLocation = () => {
    var link=this.props.href;
    this.props.setistocopen()
    this.props.setLocation(link);
  };
   
  render() {
    const { label, styles,subitems } = this.props;

    return (
      <div style={styles}>
        <div onClick={this.setLocation}>{label} </div>
        {subitems.map(item=>{
          return(
            <div onClick={()=>{ this.props.setistocopen();this.props.setLocation(item.href)}} style={styles}>{item.label}</div>
          )
        })}
      </div>
    );
  }
}

TocItem.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  setLocation: PropTypes.func,
  setistocopen:PropTypes.func,
  styles: PropTypes.object,
 
};

class ReactReader extends PureComponent {
  constructor(props) {
    super(props);
    this.readerRef = React.createRef();
    this.state = {
      expandedToc: false,
      toc: false,
      highLightBookmarkDrawBox: false,
      bookmarkColorEnable: false,
      searchDrowBox: false,
      searchValue: '',
      searchtext: '',
      highLightBookmark: [],
      searchloading:false
    };
  }


  handleSwipe = (eventData) => {
    const { dir } = eventData;
    console.log('dir', dir);
    if (dir === 'Left') {
      this.next(); // Go to next page on swipe left
    } else if (dir === 'Right') {
      this.prev(); // Go to previous page on swipe right
    }
  };  
 
    

  toggleToc = () => {
    this.props.mysettocopen(!this.state.expandedToc)
    this.setState({
      expandedToc: !this.state.expandedToc
    });
  };


  next = async () => {
    const node = this.readerRef.current;
    await node.nextPage();

  };

  prev = async () => {
    const node = this.readerRef.current;
    await node.prevPage();
  };

  onTocChange = toc => {
    const { tocChanged } = this.props;
    this.setState(
      {
        toc: toc,
      },
      () => tocChanged && tocChanged(toc)
    );
  };

  renderToc() {
    const { toc, expandedToc } = this.state;
    const { styles } = this.props;
    return (
      <div >
        <div style={{ ...styles.tocArea, backgroundColor: this.props.iconcolor.bgcolor, color: this.props.iconcolor.color }}>
          <div style={styles.toc}>
            {toc.map((item, i) => (
              <TocItem
                {...item}
                key={i}
                setistocopen={()=>{this.props.mysettocopen(!this.state.expandedToc)}}
                setLocation={this.setLocation} 
                styles={{ ...styles.tocAreaButton, color: this.props.iconcolor.color,flexDirection:"column" }}
              />
            ))}
          </div>
        </div>
        {expandedToc && (
          <div style={styles.tocBackground} onClick={this.toggleToc} />
        )}
      </div>
    );
  }

  setLocation = loc => {
    const { locationChanged } = this.props;
    this.setState(
      {
        expandedToc: false
      },
      () => locationChanged && locationChanged(loc)
    );
  };
  setLocationWithFindText = async (loc) => {
    const { locationChanged } = this.props;
    this.setState(
      {
        expandedToc: false,
        searchDrowBox: false
      },
      () => locationChanged && locationChanged(loc)
    );
    setTimeout(() => {
      this.createHighlight(loc, 'yellow', 'search')
    }, 100);
  }

  createHighlight = async (handleTextSelected, color,type) => {
    const book = this.readerRef.current;
    if (handleTextSelected) {
      book.book.getRange(handleTextSelected).then(async (range) => {
        let result = await this.markFromCfi(handleTextSelected, range, type, false, color, '');
        book.rendition.annotations.highlights.push(result);
        book.rendition.annotations.highlight(handleTextSelected, {}, (e) => {
        });
        //this.applyCustomColor(handleTextSelected, color);
        let contents = book.rendition.manager.getContents()[0];
        if (contents) {
          contents.window.getSelection().removeAllRanges();
          // Modal.destroyAll();
          this.setState({ toolbox: false });
        }
      });
    }
  }
  markFromCfi = (cfi, range, type, annotationText, color, note) => {
    return new Promise((resolve, reject) => {
      let text = range ? (range.toString() || range.startContainer.data.substring(0, 200)) : '<no text>';
      const book = this.readerRef.current;
      let spineItem = book.book.spine.get(cfi);
      let navItem = book.book.navigation.toc.find(function (element) { return (element.herf === spineItem.href) });
      if (!navItem) { navItem = { label: '' }; }
      if ((type === 'bookmark') && (navItem.label.length > text.length)) { text = navItem.label; }
      let perc = book.book.locations.percentageFromCfi(cfi);
      perc = perc ? 100 * parseFloat(perc.toFixed(2)) : 0;
      let dogear = `Chapter ${navItem.label} (${perc}%)`;
      let hl;
      let savedOn = new Date(new Date().getTime() + (60000 * 10));
      this.copyText = text;
      let labels = ''
      if (navItem.label === '') {
        labels = spineItem.idref;
      }
      else {
        labels = navItem.label;
      }

      if (note === '') {
        hl = {
          type: type, cfi: cfi, text: text, annotationText: annotationText, dogeartext: dogear, range: range,
          chapterLabel: labels, positionPercentage: perc, color: color, savedon: savedOn, underline: false,
          notesavail: false
        };
      }
      else {
        hl = {
          type: type, cfi: cfi, text: text, annotationText: annotationText, dogeartext: dogear, range: range,
          chapterLabel: labels, positionPercentage: perc, color: color, savedon: savedOn, underline: false,
          notesavail: true, note: note
        };
      }

      resolve(hl);
    });
    // return hl;
  }


  setLocationWithColor = async (loc, color) => {
    // await this.applyCustomColor(loc,color);
    const { locationChanged } = this.props;
    this.setState(
      {
        expandedToc: false,
        highLightBookmarkDrawBox: false
      },
      () => locationChanged && locationChanged(loc)
    );
    // setTimeout(() => {
    //   this.applyCustomColor(loc, color);
    // }, 100);

  }
  getEpubIframe = () => {

    let iframes = document.getElementsByTagName('iframe');
    if (iframes) {
      for (let i = 0; i < iframes.length; i++) {
        if (iframes[i].id.indexOf('epubjs') > -1) {
          return iframes[i];
        }
      }
    }
    return false;
  }
  applyCustomColor = (handleTextSelected, Hcolors) => {
    let iframe = this.getEpubIframe();
    if (iframe) {
      let svg = iframe.parentElement.getElementsByTagName('svg')[0];
      if (svg) {
        for (let i = 0; i < svg.children.length; i++) {
          let g = svg.children[i];
          if (g.dataset.epubcfi === handleTextSelected) {
            if (g.getAttribute('fill') !== Hcolors) {
              if (g.getAttribute('fill') === "yellow") {
                g.setAttribute('fill', '#fff');
                g.setAttribute('fill', Hcolors);
              } else {
                g.setAttribute('fill', Hcolors);
              }
            }
          }
        }
      }
    }
  }

  renderTocToggle() {
    const { expandedToc } = this.state;
    const { styles } = this.props;
    return (
      <button
        style={Object.assign(
          {},
          styles.tocButton,
          // expandedToc ? styles.tocButtonExpanded : {}
        )}
        onClick={this.toggleToc}
      >
        {/* <span
          style={Object.assign({}, styles.tocButtonBar, styles.tocButtonBarTop)}
        /> */}
        <AlignCenterOutlined style={{ color: this.props.iconcolor.color }} />
        {/* <span
          style={Object.assign({}, styles.tocButtonBar, styles.tocButtonBottom)}
        /> */}

      </button>
    );
  }

  drawerOnClose = () => {
    this.setState({ "highLightBookmarkDrawBox": false, "searchDrowBox": false });
  };

  showHighlighterBookmark = (type) => {
    if (type === 'Bookmark') {
      let node = this.readerRef.current
      if (node.rendition.annotations.highlights.length !== 0) {
        let bookmark = node.rendition.annotations.highlights.find(function (element) {
          if (element.type === "bookmark") {
            return (element.cfi === node.location);
          }
        });
        if (bookmark !== undefined) {
          this.setState({ bookmarkColorEnable: false });
          node.deleteBookmark(node.location, "bookmark", "f36c23")
        }
        else {
          this.setState({ bookmarkColorEnable: true });
          node.createBookmark(node.location, "bookmark", "f36c23");
          message.success('Page added to your bookmark list');
        }
      }
      else {
        this.setState({ bookmarkColorEnable: true });
        node.createBookmark(node.location, "bookmark", "f36c23");
        message.success('Page added to your bookmark list');
      }

    }
    else {
      this.setState({ "highLightBookmarkDrawBox": true });
    }

  }
  deleteTextmarkHighlight = (item) => {
    let hightlight = this.readerRef.current;
    if (hightlight.rendition.annotations.highlights != null) {
      console.log("item", this.readerRef.current.rendition.annotations.highlights)
      let bIndex = this.readerRef.current.rendition.annotations.highlights.findIndex(function (element) { return (element.cfi === item); });
      console.log("index", bIndex)
      if (bIndex > -1) {
        this.readerRef.current.rendition.annotations.highlights.splice(bIndex, 1);
      }
    }
  }
  deleteBookmarkHighlight = (item) => {
    var currentbook=localStorage.getItem("currentbook")
    currentbook=currentbook + "hl";
    var localitem = JSON.parse(localStorage.getItem(currentbook)) || [];
    localitem = localitem.filter(ditem => ditem.cfi != item.cfi);
    localStorage.setItem(currentbook, JSON.stringify(localitem));

    // let hightlight = this.readerRef.current;
    // if (hightlight.rendition.annotations.highlights != null) {
    //   let bIndex = hightlight.rendition.annotations.highlights.findIndex(function (element) { return (element.cfi == item.cfi); });
    //   if (bIndex > -1) {
    //     let deletedB = this.readerRef.current.rendition.annotations.highlights.splice(bIndex, 1);
    //   }
    // }
  }
  getHightlightAndBookmark = () => {
    var currentbook=localStorage.getItem("currentbook")
      currentbook=currentbook + "hl";
    var localitem = JSON.parse(localStorage.getItem(currentbook)) || [];

     let hightlight = this.readerRef.current;
    if (hightlight != null) {
       let getHightlightAndBookmarkData = this.readerRef.current.rendition.annotations.highlights;

      return (
        <div>
          <div style={{ width: '100%', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <p style={{ fontWeight: '500', marginTop: '14px', fontSize: "20px", marginLeft: "10px" }}>Hightlight</p>
          </div>
          {localitem.map(item => {
            if (item.type === "search") {
              return null
            } else {
              let ishi = getHightlightAndBookmarkData.find(o => o.cfi === item.cfi);
               if(!ishi){
                  this.createHighlight(item.cfi, item.color, item.type)
               }
              return (

             <div style={{  color: this.props.iconcolor.color, backgroundColor: this.props.iconcolor.bgcolor, marginLeft: '5px', marginRight: '5px',borderLeft:'1px solid',borderLeftColor:item.color,padding:'10px' ,marginTop:'5px' }} onClick={() => this.setLocationWithColor(item.cfi, item.color)} >

                  <p className="cartText" style={{ color: this.props.iconcolor.color, textAlign: "justify",}}>{item.text}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' ,}}>
                    <p className="cartDate" style={{ marginTop: "7px" }}>{item.type}</p>
                    <DeleteOutlined style={{ color: "#E81C35",fontSize:"20px",marginRight:'10px' }} onClick={() => this.deleteBookmarkHighlight(item)} />
                  </div>

                  {item.notesavail == true ?
                    <div style={{padding:'5px',backgroundColor:'#DBD9D9'}}>
                      <p className="cartType">Note :</p>
                      <p className="cartText">{item.note}</p>
                    </div> : ""
                  }
                  <p className="cartDate">{ moment(item.savedon).format('MMMM Do YYYY, h:mm a')}</p>

                </div>

              )
            }

          })}
        </div>
      )
    }
  }

  locationChanged = () => {
    let node = this.readerRef.current
    if (node.rendition.annotations.highlights.length != 0) {
      let bookmark = node.rendition.annotations.highlights.find(function (element) {
        if (element.type == "bookmark") {
          return (element.cfi == node.location);
        }
      });
      if (bookmark != undefined) {
        this.setState({ bookmarkColorEnable: true });
      }
      else {
        this.setState({ bookmarkColorEnable: false });
      }
    }
  }

  openSearch = () => {
    this.setState({ searchDrowBox: true });
  }

  onSearch = async (value) => {
    if (value != "") {
      this.setState({searchloading:true})
      let getSearchContent = await this.doSearch(value);
      console.log("search", getSearchContent)
      this.setState({ searchValue: getSearchContent, searchtext: value,searchloading:false })
    }
  }
  doSearch(q) {
    let me = this.readerRef.current;
    return Promise.all(me.book.spine.spineItems.map(item => {
      return item.load(me.book.load.bind(me.book)).then(doc => {
        void (doc);
        let results = item.find(q);
        item.unload();
        return Promise.resolve(results);
      });
    })).then(results => Promise.resolve([].concat.apply([], results)));
  }

  handleTouchStart(event) {
    console.log(event)
  }
getChapter=(loc,toc)=>{
  const result = toc.filter(  item  => item.href == loc.end.href );

  // console.log(loc,toc,result )
  
 }

  render() {
    let utility = this.props.dataStore;
    const continer = this.props.style
    const { title, showToc, loadingView, styles, locationChanged, swipeable, epubViewStyles, history, ...props } = this.props;
    const { toc, expandedToc, highLightBookmarkDrawBox, bookmarkColorEnable, searchDrowBox, searchValue } = this.state;
    return (
      <div style={styles.readerArea}>
        <div
          style={Object.assign(
            {},
            continer,
            expandedToc ? styles.containerExpanded : {}
          )}>
          <ArrowLeftOutlined style={{ color: this.props.iconcolor.color, marginTop: '15px', marginLeft: '15px', fontSize: '19px' }} onClick={()=>{history.goBack()}} />
          <div style={{ ...styles.titleArea, color: this.props.iconcolor.color,  }}>
            {title}
          </div>
          <div style={styles.rightIcons}>
            <AlignCenterOutlined style={{ color: this.props.iconcolor.color }} onClick={() => this.toggleToc()} />&#160;&#160;
            <FileSearchOutlined style={{ color: this.props.iconcolor.color }} onClick={() => this.openSearch()} />&#160;&#160;
            {/* <BookOutlined style={{ color: this.props.iconcolor.color }} onClick={() => this.showHighlighterBookmark('Bookmark')} /> */}
          </div>
          <div style={styles.reader}>
            <EpubView dataStore={utility}
              ref={this.readerRef}
              loadingView={loadingView}
              styles={epubViewStyles}
              {...props}
              getchapter={this.getChapter}
              tocChanged={this.onTocChange}
              locationChanged={this.locationChanged} />
          </div>


          <Button            
            onClick={this.prev}
            shape="circle"
            size="large"
            icon={<LeftOutlined />}
            className="centered-icon-btn"
          />

          <Button            
            onClick={this.next}
            shape="circle"
            size="large"
            icon={<RightOutlined />}
            className="centered-icon-btnr"
          />
            
        </div>
        {showToc && toc && this.renderToc()}
        <Drawer
          mask={false}
          placement="right"
          closable={true}
          closeIcon={<CloseOutlined style={{ color: this.props.iconcolor.color }} />}
          drawerStyle={{ color: this.props.iconcolor.color, backgroundColor: this.props.iconcolor.bgcolor }}
          headerStyle={{ color: this.props.iconcolor.color, backgroundColor: this.props.iconcolor.bgcolor }}
          onClose={this.drawerOnClose}
          open={highLightBookmarkDrawBox}>
          {this.getHightlightAndBookmark()}
        </Drawer>
        <Drawer
          mask={false}
          placement="right"
          closable={true}
          closeIcon={<CloseOutlined style={{color:this.props.iconcolor.color,display:"none"}} />}
          onClose={this.drawerOnClose}
          drawerStyle={{color:this.props.iconcolor.color,backgroundColor:this.props.iconcolor.bgcolor}}

          open={searchDrowBox}>
             <div style={{ height: '60px', display: 'flex', marginLeft:"30px", justifyContent: 'space-between' }}>
          <p style={{ fontWeight: '500',marginTop:'14px',fontSize:"20px" }}>Search</p>
          <CloseOutlined style={{color:this.props.iconcolor.color,marginRight:'15px',fontSize:'16px'}} onClick={this.drawerOnClose} />
          {/* <CloseOutlined  style={{color:epubstyle.color}} onClick={onClose}/> */}
        </div>
          <Search style={{ height: "40px", padding:"30px" }} placeholder="Search" onSearch={this.onSearch} enterButton loading={this.state.searchloading} />
          {searchValue != '' ?
            <div>
              {searchValue.map(item => (
                <Card style={{ margin: '20px' }} className="searchContent" onClick={() => this.setLocationWithFindText(item.cfi)}> 
                {item.excerpt} 
                </Card>
              ))}
            </div> : ""}
        </Drawer>

      </div>
    );
  }
}

ReactReader.defaultProps = {
  loadingView: <div style={defaultStyles.loadingView}>Loading…</div>,
  locationChanged: null,
  selected: null,
  tocChanged: null,
  showToc: true,
  styles: defaultStyles
};

ReactReader.propTypes = {
  title: PropTypes.string,
  loadingView: PropTypes.element,
  showToc: PropTypes.bool,
  locationChanged: PropTypes.func,
  tocChanged: PropTypes.func,
  selected: PropTypes.func,
  styles: PropTypes.object,
  epubViewStyles: PropTypes.object,
  swipeable: PropTypes.bool
};

const mapStateToProps = state => ({
  style: state.App.readerArea,
  iconcolor: state.App.epubstyle,

})
const mapDispatchToProps = {
  mysettocopen:settocopen,
 
}

export default connect(mapStateToProps,mapDispatchToProps)(ReactReader);
