import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pdfURL, bookcover,articlepdfURL } from '../../api/api';
import PDFViewer from '../../component/PDFViewer/PDFViewer';
import PDFJSBackend from '../../backends/pdfjs';
import Header from '../../component/pageheader/pageHeader';
import { Spin, Col, Row,Breadcrumb,message,Progress} from 'antd';
import { useIndexedDB } from 'react-indexed-db';
import {LoadingOutlined,ArrowLeftOutlined} from '@ant-design/icons';
import { useHistory,useLocation } from 'react-router-dom'
import Bookcover from '../../api/images/book.png';
import Axios from "axios";
import { ASSET_URL, SERVER_URL } from '../../api/content-novareader';
import CryptoJS from 'crypto-js';
import PageHader from '../../component/pageheader/pageHeader';

const Pdf = (props) => { 
  const history = useHistory();
  const nlocation = useLocation();
  const contentid=nlocation.state.contentid
  const fuzzyid= nlocation.state.fuzzyid
  const isbn=nlocation.state.isbn
  const titel=nlocation.state.name
  const elasticid=props.match.params.elasticid
  const isonline=nlocation.state.isonline
  const type=nlocation.state.type
  const cover=nlocation.state.cover
  const isorg=nlocation.state.isorg
  const bookinfo=nlocation.state.info
  const author=nlocation.state.auth
  const [coverImage, setCoverImage] = useState(null);
  const org = JSON.parse(localStorage.getItem('lpporg'));
  const { add, getAll } = useIndexedDB('book');
  
  const [location, setlocation] = useState('')
  const [loading, setloading] = useState(false)
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });
 const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));
 const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  useEffect(() => {
    setloading(true);
    findByNameAndType(titel, 'pdf', lpp_userCredentials).then(results => {
      if (results.length>0) {
        message.success('Loading ....');
        var file = results[0];
        setCoverImage(URL.createObjectURL(file.img));
        blobToString(file.file)
          .then(async (encryptedData) => {
            // Step 3: Decrypt the encrypted data
              const keyBytes = new TextEncoder().encode("1234567890123456");
          
              // Import AES key for decryption
              const cryptoKey = await window.crypto.subtle.importKey(
                "raw",
                keyBytes,
                { name: "AES-CTR" },
                false,
                ["decrypt"]
              );
          
              // Extract IV (first 16 bytes) and ciphertext
              const iv = encryptedData.slice(0, 16);
              const ciphertext = encryptedData.slice(16);
          
              // Perform AES-CTR decryption
              const decryptedBuffer = await window.crypto.subtle.decrypt(
                { name: "AES-CTR", counter: iv, length: 64 },
                cryptoKey,
                ciphertext
              );
  
            const epubBlob = new Blob([decryptedBuffer]);
            const url = URL.createObjectURL(epubBlob);
            localStorage.setItem('pdfurl', url);
            setlocation(epubBlob)
            setloading(false)
    
          });
      } else {
        getdata(lpp_userCredentials.apiKey);
      }
    });
  }, [])

  const blobToString = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result); // Read as string
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob); // Read Blob as text
    });
  };

  async function findByNameAndType(name, type, lpp_userCredentials) {
    try {
      const allRecords = await getAll(); // Get all records from the 'book' store
      
      // Filter by name and type
      const results = allRecords.filter(
        (record) => record.name === name && record.type === type && record.userId === lpp_userCredentials.id
      );
      return results;
    } catch (error) {
      console.error('Error fetching records:', error);
    }
  }
  
  const getdata = (apikey) => {
    message.success('Downloading ....');
  
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };
  
    Axios.get(`${SERVER_URL}content/${type}/webpdf/${fuzzyid}.pdf`, {
      responseType: "arraybuffer",
      headers: { 'apikey': apikey },
      ...options,
    })
      .then((encryptedData) => {
        Axios.get(`${ASSET_URL}${fuzzyid}/${cover}`, { responseType: "blob" })
          .then(async (response) => {
            setCoverImage(URL.createObjectURL(response.data));
            const encContent = encryptedData.data;
            const content = encryptedData.data;
            const keyBytes = new TextEncoder().encode("1234567890123456");
        
            // Import AES key for decryption
            const cryptoKey = await window.crypto.subtle.importKey(
              "raw",
              keyBytes,
              { name: "AES-CTR" },
              false,
              ["decrypt"]
            );
        
            // Extract IV (first 16 bytes) and ciphertext
            const iv = content.slice(0, 16);
            const ciphertext = content.slice(16);
        
            // Perform AES-CTR decryption
            const decryptedBuffer = await window.crypto.subtle.decrypt(
              { name: "AES-CTR", counter: iv, length: 64 },
              cryptoKey,
              ciphertext
            );
  
            const epubBlob = new Blob([decryptedBuffer]);
            const url = URL.createObjectURL(epubBlob);
            localStorage.setItem('pdfurl', url);
  
            // IndexedDB Insert
            const encryptedBlob = new Blob([encContent], { type: 'application/octet-stream' });

            add({
              name: titel,
              file: encryptedBlob,
              img: response.data,
              type: "pdf",
              info: bookinfo,
              auth: author,
              userId: lpp_userCredentials.id
            })
              .then(() => {
                setlocation(epubBlob)
                setloading(false)
              })
              .catch((error) => {
                setlocation(epubBlob)
                setloading(false)
              });
          })
          .catch((error) => {
            console.error("Cover Image Fetch Error:", error);
            message.error('Failed to fetch cover image.');
            setloading(false);
          });
      })
      .catch((error) => {
        console.error("EPUB Fetch Error:", error);
        message.error('Sorry, something went wrong. Please try again!');
        setTimeout(() => {
          history.goBack();
        }, 3000);
      });
  };    


if (loading) {
    return (
      <div>
        <PageHader titel={titel}/>
        <Row>
          <Col style={{ height: '95vh', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
            <p style={{ letterSpacing: '1px', marginTop: '10px',fontFamily:'var(--font)' }}></p>
            <img src={coverImage} style={{ height: '80%', width: '100%', backgroundColor: '#cccccc' }} alt="" loading="lazy" />
              <div style={{ height: '20%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', backgroundColor: "#ffffff",flexDirection:'column',padding:'10px' }}>
                <Progress percent={downloadInfo.progress} size="default" status="active"  />
              </div>
          </Col>
        </Row>
      </div>
    )
  }
  return (
    <PDFViewer
      backend={PDFJSBackend}
      src={location}
    />
  )
}

export default Pdf;