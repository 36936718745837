import Logo from './images/logo.png';
import Loginimg from './images/computer.png';
export const SERVER_URL = "https://demo.novareader.co/";
export const ASSET_URL = "https://demo.novareader.co/unsecured/";
export const REST_API_URL = SERVER_URL+"api/v1";
export const APP_NAME="International Pain and Spine Intervention Society";
export const LOGO=Logo;
export const LOGINIMG=Loginimg;
export const FbLoginID= 1007931699847440;
export const AppleClientId="co.novareader.researchpad";
export const GmailClientId="458582913994-ufc1of4nlhc3vmc9h89rdhupeon86bss.apps.googleusercontent.com";
export const OrcidClientId="https://orcid.org/oauth/authorize?client_id=APP-NE6GOPUF09930F9B&response_type=code&scope=/authenticate&redirect_uri=https://mobile.novareader.co/login";
export const URL="https://mobile.novareader.co/";
export const AppVersion="v1.1.5";
export const RedirectUri="https://content.novareader.co/api/applelogin";
export const Cover="https://demo.novareader.co/unsecured/";
export const YM_LOGIN_URL = `https://www.ipsismed.org/lock.aspx?app_id=25a4d100-9428-4a91-94cf-ba7fd002ef4b&redirect_uri=${SERVER_URL}mobile/ym-access-token&scope=basic_profile`;







