import React, { useState,useEffect } from "react";
import { Result,Button } from 'antd';
import{useHistory}from 'react-router-dom'

const Error500=(props)=> {
    const history=useHistory();
 return (
        <div style={{display: 'flex', height:'50%', width: '100%', alignItems: "center", justifyContent: 'center',marginTop:props.mtop? props.mtop:'100px' }}>
        <Result
            status="500"
            title=""
            subTitle="Sorry, something went wrong."
            extra={<Button className="cls-btn" onClick={()=>window.location.reload()} type="primary">Refresh</Button>}/> 
        </div>
    );
  
}

export default Error500;
