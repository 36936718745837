import React, { useState, useEffect } from 'react';
import Header from '../../component/dashboard/header';
import './styles.css';
import Subscription from './subscription';
import Favorite from './Favorite';
import Downloaded from './downloaded';
import 'antd-mobile/dist/antd-mobile.css';
import { Row, Col,} from 'antd';
import { useHistory } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux';
import { settab } from '../../redux/actions/dashboardActions';
import Space from '../../component/dashboard/space';

const Library = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    const tab = useSelector(state => state.App.tab);

    useEffect(() => {
        window.scrollTo(0, 0);
        const userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));

        if (!userCredentials || userCredentials.emailVerified !== 1) {
            history.push('/login');
        }
        setIsLoading(false)
    }, [history]);

    // Tab change handlers
    const setSub = () => dispatch(settab("sub"));
    const setDow = () => dispatch(settab("dow"));

    return (
        <div>
            <Row style={{ padding: "0px", margin: "0px" }}>
                <Col xs={24} lg={{ span: 20, offset: 2 }}>
                    {/* Tab Bar */}
                    <div className="tabbar">
                        <div 
                            className="tabbar_item" 
                            onClick={setSub} 
                            style={{ 
                                borderBottom: tab === "sub" ? "3px solid #B11116" : "1px solid #CFD3D2", 
                                color: tab === "sub" ? "#B11116" : "#CFD3D2" 
                            }}>
                            Subscribed
                        </div>
                        <div 
                            className="tabbar_item" 
                            onClick={setDow} 
                            style={{ 
                                borderBottom: tab === "dow" ? "3px solid #B11116" : "1px solid #CFD3D2", 
                                color: tab === "dow" ? "#B11116" : "#CFD3D2" 
                            }}>
                            Downloaded
                        </div>
                    </div>

                    {/* Render Components Based on Selected Tab */}
                    {tab === "sub" && <Subscription setdownloade={setDow} />}
                    {tab === "dow" && <Downloaded setsub={setSub} />}
                </Col>
            </Row>
        </div>
    );   
}

export default Library;