import React, { useEffect, useState } from 'react'
import List from './page/book&journallist/book_journals_List';
import Reader from './page/Reader';
import Login from './page/login/LoginScreen';
import Home from './page/dashboard/home';
import Pdf from './page/pdf/pdf';
import NewDescription from './page/description/newdescription';
import IntDescription from './page/description/description';
import Offline from './page/offline';
import Acsetting from './page/setting/account';
import Bookbysubject from './page/book/booksbysubject';
import Bookbycategory from './page/book/booksbycategory';
import Booksbycollection from './page/book/bookbycollection';
import Journal from './page/description/journal';
import Resetpassword from './page/login/resetpassword';
import Search from './page/search/search';
import Dowonloaded from './page/library/downloadedpage';
import "antd/dist/antd.css";
import './App.css';
import { DBConfig } from './DBConfig';
import { initDB } from 'react-indexed-db';
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
import { appName } from './api/api';
import { message} from 'antd';
import PageNotFound from './component/PageNotFound';
import Collections from './page/dashboard/collectios';
import Institutions from './page/dashboard/Institutionshome';
import Allcollection from './page/dashboard/allcollection';
import Institution from './page/dashboard/Institution';
import { Result} from 'antd';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Library from './page/library/library';

initDB(DBConfig);

function App() {
  const[isOnLine,setIsOnLine]=useState("online")
 
   
  useEffect(() => {
    document.title = appName;
    localStorage.setItem('isonline', "online")

  },[])
  window.addEventListener("load", () => {
    navigator.onLine
      ? console.log("You are online")
      :console.log("You are offline");
       window.addEventListener("online", () => {
       message.success('You are online back !!');
       localStorage.setItem('isonline', "online")

    });
    window.addEventListener("offline", () => {
      if(isOnLine === "online" ){
        setIsOnLine("offline")
        localStorage.setItem('isonline', "offline")
        message.warning('You are offline !!');
     }
   });
  });

  return (
    <>
     <BrowserView>
     
     <div className='iscomp'>
     <Result
    status="warning"
    title=""
    subTitle="This page rendered only on Mobile device."
  />  
     </div>
    
    
          
         </BrowserView>
         <MobileView>

    <Router>
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route exact path="/login" component={Login}/>
      <Route exact path="/resetpassword" component={Resetpassword}/>
      <Route exact path="/list" component={List}/>
      <Route exact path="/read/book/epub/:elasticid" component={Reader}/>
      <Route exact path="/read/book/pdf/:elasticid" component={Pdf}/>
      <Route exact path="/book/isbn/:isbn/" component={NewDescription}/>
      <Route exact path="/institutions/book/isbn/:isbn/" component={IntDescription}/>
      <Route exact path="/bookbysubject/:subject/" component={Bookbysubject}/>
      <Route exact path="/bookbycategory/:category/" component={Bookbycategory}/>
      <Route exact path="/bookbycollection/:subject/" component={Booksbycollection}/>
      <Route exact path="/journal/:category/:id/" component={Journal}/>
      <Route exact path="/offline" component={Offline}/>
      <Route exact path="/downloaded" component={Home}/>
      <Route exact path="/setting" component={Acsetting}/>
      <Route exact path="/search" component={Search}/>
      <Route exact path="/collections" component={Collections}/>
      <Route exact path="/allcollections" component={Allcollection}/>
      <Route exact path="/institutions" component={Institutions}/>
      <Route exact path="/institution" component={Institution}/>

      <Route path="*" component={PageNotFound} />

    </Switch>
  </Router>
  </MobileView>

  </>
  );
}

export default App;
