import React from 'react';
import { useSwipeable } from 'react-swipeable';

const Swaip = ({ onNextPage, onPreviousPage }) => {
  // Define the swipeable handlers with direction-specific callbacks
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      console.log("Swiped Left! Moving to the next page.");
      onNextPage(); // Trigger the function to go to the next page
    },
    onSwipedRight: () => {
      console.log("Swiped Right! Moving to the previous page.");
      onPreviousPage(); // Trigger the function to go to the previous page
    },
    // Optionally: limit swipe sensitivity or other settings
    delta: 50, // Controls the sensitivity for swipe recognition
  });

  return <div {...handlers} style={{ width: '100%', height: '100%' }} />;
}

export default Swaip;
