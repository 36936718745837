import SkeletonElament from './skeletonelament';
import Shimmer from './shimmer';


const skeletonbook=()=>{
    return(
        <div className="cards-container">
        {[1,2,3,4].map((item, index)=>{
            return(
                <div key={index} className="ion-card" >
                <div className="bookcover_continer" >
                    <div  className="skeletonbookcover">
                     <SkeletonElament type="cover"/>
                </div>
                </div>
                <div className="bookcontent">
                    <div>
                        <SkeletonElament type="titel"/>
                        <SkeletonElament type="sub1"/>
                        <SkeletonElament type="sub2"/>
                        <SkeletonElament type="sub3"/>
                    </div>
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '10px' }}>
                        <SkeletonElament type="btton"/>
                    </div>
                </div>
                <Shimmer/>
            </div>
            )
        })}
       
    </div>
    )
}

export default skeletonbook;